/**
 * Generate CSS styles for the chat widget with dynamic color
 * @param {string} color - Primary color for the chat widget
 * @returns {string} - CSS styles as a string
 */
export function getStyles(color) {
    return `
      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      }
  
      #chat-widget-container {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 9999;
        font-size: 16px;
      }
  
      #chat-bubble {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        position: relative;
        z-index: 10;
        background-color: ${color};
      }
  
      #chat-bubble:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
      }
  
      #chat-bubble svg {
        width: 30px;
        height: 30px;
        transition: transform 0.2s ease;
      }
  
      #chat-bubble:hover svg {
        transform: rotate(5deg);
      }
  
      #chat-popup {
        position: absolute;
        bottom: 80px;
        right: 0;
        width: 360px;
        max-width: 90vw;
        height: 500px;
        max-height: 80vh;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        transition: opacity 0.3s ease, transform 0.3s ease;
        display: flex;
        flex-direction: column;
      }
  
      #chat-popup.hidden {
        opacity: 0;
        transform: translateY(20px) scale(0.95);
        pointer-events: none;
      }
  
      #chat-header {
        padding: 16px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        background-color: ${color};
      }
  
      #chat-title {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.3px;
        position: relative;
        padding-left: 16px;
        margin: 0;
      }
  
      #chat-title::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: #50e3c2;
        border-radius: 50%;
        box-shadow: 0 0 0 3px rgba(80, 227, 194, 0.2);
      }
  
      #close-popup {
        background: none;
        border: none;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease, transform 0.2s ease;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: white;
      }
  
      #close-popup:hover {
        opacity: 1;
        transform: rotate(90deg);
        background-color: rgba(0, 0, 0, 0.05);
      }
  
      #chat-messages {
        flex: 1;
        padding: 16px;
        overflow-y: auto;
        background-color: #f8f9fa;
        display: flex;
        flex-direction: column;
        gap: 12px;
        scroll-behavior: smooth;
      }
  
      #chat-messages::-webkit-scrollbar {
        width: 6px;
      }
  
      #chat-messages::-webkit-scrollbar-track {
        background: transparent;
      }
  
      #chat-messages::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
  
      .message {
        padding: 12px 16px;
        border-radius: 18px;
        max-width: 80%;
        word-wrap: break-word;
        position: relative;
        animation: fadeIn 0.3s ease;
      }
  
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(10px); }
        to { opacity: 1; transform: translateY(0); }
      }
  
      #chat-input-container {
        padding: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        background-color: white;
      }
  
      .flex {
        display: flex;
      }
  
      .space-x-4 {
        gap: 16px;
      }
  
      .items-center {
        align-items: center;
      }
  
      #chat-input {
        flex: 1;
        padding: 12px 16px;
        border: 1px solid #e1e4e8;
        border-radius: 24px;
        outline: none;
        font-size: 14px;
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
      }
  
      #chat-input:focus {
        border-color: rgba(74, 70, 255, 0.5);
        box-shadow: 0 0 0 2px rgba(74, 70, 255, 0.1);
      }
  
      #chat-submit {
        padding: 10px 20px;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        transition: background-color 0.2s ease, transform 0.2s ease;
        color: white;
        background-color: ${color};
      }
  
      #chat-submit:hover {
        opacity: 0.9;
        transform: translateY(-1px);
      }
  
      #chat-submit:active {
        transform: translateY(1px);
      }
  
      .flex.text-center.text-xs.pt-4 {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        font-size: 12px;
        color: #8b8b8b;
      }
  
      .flex-1 {
        flex: 1;
      }
  
      .text-indigo-600 {
        color: #4f46e5;
        text-decoration: none;
        font-weight: 500;
        transition: color 0.2s ease;
      }
  
      .text-indigo-600:hover {
        color: #4338ca;
        text-decoration: underline;
      }
  
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-20px);
        }
        60% {
          transform: translateY(-10px);
        }
      }
  
      .bounce {
        animation: bounce 1s ease;
        animation-iteration-count: 3;
      }
      
      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(74, 70, 255, 0.4);
        }
        70% {
          box-shadow: 0 0 0 10px rgba(74, 70, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(74, 70, 255, 0);
        }
      }
      
      .notification-dot {
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        background-color: #ff4757;
        border-radius: 50%;
        border: 2px solid white;
        animation: pulse 2s infinite;
      }
  
      .typing-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .typing-indicator span {
        height: 8px;
        width: 8px;
        background-color: #888;
        border-radius: 50%;
        display: inline-block;
        margin: 0 2px;
        opacity: 0.4;
      }
      
      .typing-indicator span:nth-child(1) {
        animation: typing 1s infinite ease-in-out;
      }
      
      .typing-indicator span:nth-child(2) {
        animation: typing 1s infinite ease-in-out 0.2s;
      }
      
      .typing-indicator span:nth-child(3) {
        animation: typing 1s infinite ease-in-out 0.4s;
      }
      
      @keyframes typing {
        0% { transform: translateY(0); opacity: 0.4; }
        50% { transform: translateY(-5px); opacity: 1; }
        100% { transform: translateY(0); opacity: 0.4; }
      }
  
      @media (max-width: 640px) {
        #chat-popup {
          width: calc(100vw - 40px);
          height: 400px;
          max-height: 70vh;
          bottom: 80px;
          right: 0;
        }
        
        #chat-bubble {
          width: 56px;
          height: 56px;
        }
        
        #chat-bubble svg {
          width: 24px;
          height: 24px;
        }
        
        #chat-header, #chat-input-container {
          padding: 12px;
        }
        
        #chat-input {
          font-size: 14px;
          padding: 10px 14px;
        }
        
        #chat-submit {
          padding: 8px 16px;
        }
        
        .message {
          max-width: 85%;
          font-size: 14px;
        }
      }
  
      @media (max-width: 360px) {
        #chat-widget-container {
          bottom: 10px;
          right: 10px;
        }
        
        #chat-popup {
          width: calc(100vw - 20px);
          bottom: 70px;
        }
        
        .space-x-4 {
          gap: 8px;
        }
        
        #chat-submit {
          padding: 8px 12px;
          font-size: 13px;
        }
      }
    `;
  }
// Environment-specific configurations
const ENV_CONFIG = {
    production: {
      API_BASE_URL: 'https://slama-chat.aamlshaju.com'
    },
    development: {
      API_BASE_URL: 'http://localhost:8008'
    }
  };
  
  // Get current environment from build process or default to development
  const ENVIRONMENT = process.env.NODE_ENV || 'development';
  
  // Export configuration based on environment
  export const API_BASE_URL = ENV_CONFIG[ENVIRONMENT].API_BASE_URL;
  
  // Other application constants
  export const DEFAULT_CHAT_NAME = 'Chat Widget';
  export const DEFAULT_CHAT_COLOR = '#1f2937';
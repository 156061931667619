import { API_BASE_URL, DEFAULT_CHAT_NAME, DEFAULT_CHAT_COLOR } from './constants.js';
import { getStyles } from './styles.js';

/**
 * Chat Assistant Web Component
 * 
 * A customizable chat widget that can be embedded in any website.
 * Requires org-id attribute and supports optional name and color attributes.
 */
class ChatAssistant extends HTMLElement {
  static get observedAttributes() {
    return ['name', 'org-id', 'color'];
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    
    // Initialize properties
    this.orgId = this.getAttribute('org-id');
    this.color = this.getAttribute('color') || DEFAULT_CHAT_COLOR;
    this.chatName = this.getAttribute('name') || DEFAULT_CHAT_NAME;
    this.threadId = null;
    
    this.initialize();
  }

  /**
   * Initialize the component based on available attributes
   */
  initialize() {
    if (this.orgId) {
      this.render();
    } else {
      console.error('Error: org-id attribute is required for chat-assistant');
      this.shadowRoot.innerHTML = `<div>Chat widget requires an organization ID</div>`;
    }
  }

  /**
   * Update component when attributes change
   */
  attributeChangedCallback(name, oldValue, newValue) {
    if (!newValue || oldValue === newValue) return;
    
    switch (name) {
      case 'name':
        this.chatName = newValue;
        const titleElement = this.shadowRoot?.getElementById('chat-title');
        if (titleElement) {
          titleElement.textContent = newValue;
        }
        break;
      case 'color':
        this.color = newValue;
        this.render();
        break;
      case 'org-id':
        this.orgId = newValue;
        if (!oldValue) {
          this.render();
        } else {
          // Reset session on org-id change
          this.threadId = null;
        }
        break;
    }
  }

  /**
   * Start a new chat session with the server
   */
  async startSession() {
    if (this.threadId) return; // Skip if we already have a session
    
    this.showTypingIndicator();
    
    try {
      const response = await fetch(`${API_BASE_URL}/chat/start/${this.orgId}`, {
        method: 'POST'
      });
      
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }
      
      const data = await response.json();
      this.threadId = data.thread_id;
      
      console.log(`Session started with ID: ${this.threadId}`);
      
      // Add welcome message
      this.appendMessage(data.welcome_message, 'bot');
    } catch (error) {
      console.error('Error starting chat session:', error);
      // Show error message in the chat
      this.appendMessage('Error starting chat session. Please try again later.', 'bot');
    } finally {
      this.hideTypingIndicator();
    }
  }

  /**
   * Render the complete chat widget UI
   */
  render() {
    this.shadowRoot.innerHTML = this.getTemplate();
    this.setupEventListeners();
  }

  /**
   * Get the HTML template for the chat widget
   */
  getTemplate() {
    return `
      <link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.16/tailwind.min.css" rel="stylesheet">
      <style>
        ${getStyles(this.color)}
      </style>
      <div id="chat-widget-container">
        <div id="chat-bubble">
          <div class="notification-dot"></div>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
          </svg>
        </div>
        
        <div id="chat-popup" class="hidden">
          <div id="chat-header">
            <h3 id="chat-title">${this.chatName}</h3>
            <button id="close-popup">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div id="chat-messages"></div>
          <div id="chat-input-container">
            <div class="flex space-x-4 items-center">
              <input type="text" id="chat-input" placeholder="Type your message...">
              <button id="chat-submit">Send</button>
            </div>
            <div class="flex text-center text-xs pt-4">
              <span class="flex-1">Powered by <a href="https://slama.chat" target="_blank" class="text-indigo-600">Slama Chat</a></span>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  /**
   * Set up all event listeners
   */
  setupEventListeners() {
    const chatInput = this.shadowRoot.getElementById('chat-input');
    const chatSubmit = this.shadowRoot.getElementById('chat-submit');
    const chatBubble = this.shadowRoot.getElementById('chat-bubble');
    const closePopup = this.shadowRoot.getElementById('close-popup');

    if (chatSubmit) {
      chatSubmit.addEventListener('click', () => this.sendUserMessage());
    }
    
    if (chatInput) {
      chatInput.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.sendUserMessage();
        }
      });
    }
    
    if (chatBubble) {
      chatBubble.addEventListener('click', () => this.handleBubbleClick());
    }
    
    if (closePopup) {
      closePopup.addEventListener('click', () => this.togglePopup());
    }
  }

  /**
   * Handle chat bubble click - open popup and start session if needed
   */
  async handleBubbleClick() {
    this.togglePopup();
    
    if (!this.threadId) {
      await this.startSession();
    }
  }

  /**
   * Toggle chat popup visibility
   */
  togglePopup() {
    const chatPopup = this.shadowRoot.getElementById('chat-popup');
    chatPopup.classList.toggle('hidden');
    
    if (!chatPopup.classList.contains('hidden')) {
      this.shadowRoot.getElementById('chat-input').focus();
    }
  }

  /**
   * Handle user message sending
   */
  async sendUserMessage() {
    const chatInput = this.shadowRoot.getElementById('chat-input');
    const message = chatInput.value.trim();
    
    if (!message || !this.threadId) return;

    chatInput.value = '';
    
    // Create a user message with status placeholder
    const userMessageId = `msg-${Date.now()}`;
    this.appendMessage(message, 'user', userMessageId);
    
    this.showTypingIndicator();

    try {
      const response = await fetch(`${API_BASE_URL}/chat/send`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          organisation_id: this.orgId, 
          thread_id: this.threadId, 
          message, 
          sender: 'user' 
        })
      });
      
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }
      
      const data = await response.json();
      
      // Update user message status to delivered
      this.updateMessageStatus(userMessageId, 'delivered');
      
      // Display bot response
      if (data.response) {
        this.appendMessage(data.response, 'bot');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      
      // Update user message with error status
      this.updateMessageStatus(userMessageId, 'error');
      
      // Show error message
      this.appendMessage('Error: Unable to send message.', 'bot');
    } finally {
      this.hideTypingIndicator();
    }
  }

  /**
   * Show typing indicator while waiting for response
   */
  showTypingIndicator() {
    const chatMessages = this.shadowRoot.getElementById('chat-messages');
    const typingElement = document.createElement('div');
    typingElement.id = 'typing-indicator';
    typingElement.className = 'flex justify-start mb-3';
    
    typingElement.innerHTML = `
      <div class="bg-gray-200 text-black rounded-lg py-2 px-4">
        <div class="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    `;
    
    chatMessages.appendChild(typingElement);
    chatMessages.scrollTop = chatMessages.scrollHeight;
  }

  /**
   * Hide typing indicator
   */
  hideTypingIndicator() {
    const typingIndicator = this.shadowRoot.getElementById('typing-indicator');
    if (typingIndicator) {
      typingIndicator.remove();
    }
  }

  /**
   * Update message status indicator (for delivered/error)
   */
  updateMessageStatus(messageId, status) {
    const messageStatusElement = this.shadowRoot.getElementById(`${messageId}-status`);
    if (!messageStatusElement) return;
    
    if (status === 'delivered') {
      messageStatusElement.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-green-500">
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      `;
    } else if (status === 'error') {
      messageStatusElement.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-red-500">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      `;
    }
  }

  /**
   * Load messages for the current thread
   */
  async loadMessages() {
    if (!this.threadId) {
      console.warn("Cannot load messages without a session ID");
      return;
    }
    
    try {
      const response = await fetch(`${API_BASE_URL}/chat/${this.orgId}/${this.threadId}`);
      
      if (!response.ok) {
        throw new Error(`Failed to load messages: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Clear existing messages
      const chatMessages = this.shadowRoot.getElementById('chat-messages');
      chatMessages.innerHTML = '';
      
      // Add each message
      data.messages.forEach(msg => {
        this.appendMessage(msg.message, msg.sender);
      });
    } catch (error) {
      console.error('Error loading messages:', error);
    }
  }
  
  /**
   * Append a message to the chat
   */
  appendMessage(message, sender, messageId = null) {
    const chatMessages = this.shadowRoot.getElementById('chat-messages');
    const messageElement = document.createElement('div');
    
    const isUser = sender === 'user';
    messageElement.className = `flex ${isUser ? 'justify-end' : 'justify-start'} mb-3`;
    
    // Generate a message ID if not provided
    const id = messageId || `msg-${Date.now()}`;
    messageElement.id = id;
    
    // Add status indicator for user messages
    const statusHtml = isUser ? `<span id="${id}-status" class="message-status"></span>` : '';
    
    messageElement.innerHTML = `
      <div class="flex items-center">
        <div class="${isUser ? 'bg-gray-500 text-white' : 'bg-gray-200 text-black'} rounded-lg py-2 px-4 max-w-[70%] whitespace-pre-line">${message}</div>
        ${statusHtml}
      </div>
    `;
    
    chatMessages.appendChild(messageElement);
    chatMessages.scrollTop = chatMessages.scrollHeight;
  }
}

// Register the custom element
customElements.define('chat-assistant', ChatAssistant);

export default ChatAssistant;